import { render, staticRenderFns } from "./checkToken.vue?vue&type=template&id=6ec4972e&scoped=true&"
import script from "./checkToken.vue?vue&type=script&scoped=true&lang=js&"
export * from "./checkToken.vue?vue&type=script&scoped=true&lang=js&"
import style0 from "./checkToken.vue?vue&type=style&index=0&id=6ec4972e&lang=stylus&rel=stylesheet%2Fstylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec4972e",
  null
  
)

export default component.exports