//控制系统升级弹框显示
export function  getUpgrade(vm){
    vm.loginShow = false;
    let time = new Date().getTime();
    let upgradeStart = 0;//2月29日晚上7点
    let upgradeEnd = 1;//12月30日早上8点
    if(upgradeStart<=time&&upgradeEnd>=time){
      vm.loginShow = true;
    }else{
      vm.loginShow = false;
    }
  }
  