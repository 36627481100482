<template>
  <div class="check-token">
    <section class="check-token__box">
      <!-- <img src="/static/images/status/pc.jpg" class="check-token__box--pc" /> -->
      <img src="/img/status/loading.gif" class="check-token__box--loading" />
      <p class="check-token__box--txt">{{ messageText }}</p>
    </section>
  </div>
</template>

  <script scoped>
import { mapGetters, mapState } from "vuex";
// import Modal from 'components/modal/modal'
import { getUpgrade } from "./login-show";
// import { config } from '@/router/config.js'
// import axios from 'axios'

const systemCode = "S04SU25"; // S08SU03  S04SU17

export default {
  props: {},
  // components: { Modal },
  data() {
    return {
      messageText: "",
      needVpn: false,
      isClickone: true,
      noticeModals: false,
      accounts: "",
      success: false,
      passwards: "",
      numcode: "",
      token: "",
      alermtext: "账号或密码错误",
      alermShow: false,
      keepPsw: false,
      authList: [],
      verifyPage: {
        isCenter: false,
        isPerson: false,
        isIndex: false,
        isObligation: false,
        isWarning: false,
        isAnalysis: false,
        isReport: false,
        isSTatistics: false,
        isAbroad: false,
        isCollection: false,
        isSettings: false,
        isDuty: false,
        isFileupload: false,
        isAlerm: true, //系统通知(xjs反馈没人个人都应该有这个权限)
      },
      pageControl: {
        isTask: false, //obligation 巡查任务
        isInvestigation: false, //obligation 漏控倒查
        isAll: false, //search 全网搜索
        isWeibo: false, //search 微博搜索
        isPush: false, //warning 预警推送
        isUnitMonitor: false, //warning 单位监测
        isMonitor: false, //warning 自主监测
        isOutsideWarn: false, //warning 境外舆情
        isFast: false, //report 舆情块报
        isCenter: false, //report 上报中心
        isApp: false, //report APP推送
        isSpecial: false, //report App  舆情专报
        isMsg: false, //report App  专报信息推送
        isNetsafe: false, //report App  网安动态推送
        information: false, //report App  咨迅推送列表
        isWorkmanage: false, //duty  值班管理
        isWorknote: false, //duty  值班日志
        isNotice: false, //settings  舆情预警
        isNavigation: false, //settings  境外导航
        isDeleteitem: false, //settings  删除的信息
        isFramework: false, //settings  组织架构
        isMycollect: false, //settings 我的收藏分类
        isShift: false, //settings  班次管理
        isPatrol: false, //settings  责任巡查
        isWarningsend: false, //settings  预警推送
        isMonitoring: false, //settings  自主监测
        isWatchDept: false, //settings  单位监测
        isManageWarning: false, //person  信息预警
        isPersonWarning: false, //person  人员预警
        isPersonnelAnalysis: false, //person  人员分析
        isEventWarning: false, //settings 重点人事件分析
        isManWarning: false, //settings 重点人人员分析
      },
      bankTime: "", //账号的期限时间
      zhuhaiArr: [
        // 珠海 deptId  11
        {
          orderNumber: 0,
          children: [],
          menuLink: "/bank",
          menuId: 334,
          menuName: "线索库",
          menuParentId: 0,
        },
      ],
      loginShow: false, //控制登陆框显示
      upgradeTime: "12月29日晚上7点到12月30日早上8点", //升级时间
      companyInfo: {}, // 部门信息
      deptSystem: {}, // 部门信息
    };
  },
  created() {
    this.checkMeiyaToken();
    // console.log(154,this.loginServer);
  },
  mounted() {
    // getUpgrade(this);
    // let that = this;
    // if (window.localStorage) {
    //   this.getLocation()
    // }
    // window.onkeydown = function (e) {
    //   if (e.keyCode == 13) {
    //     that.loginIn()
    //   }
    // }
  },
  methods: {
    // 格式化获取url参数 问号后面的内容解析 ?token=1232131&type=1 token=1232131
    formatUrlData(str) {
      let attr = {};
      // 空值检测 错误检测
      if (!str || str.indexOf("=") === -1) {
        return attr;
      }
      // 1. 首先检测是否存在 & 符号 如果没有
      if (str.indexOf("&") === -1) {
        attr[str.split("=")[0]] = str.replace(/\s*/g, "").split("=")[1];
      } else {
        let arr = str.split("&");
        arr.forEach((v, index) => {
          attr[v.split("=")[0]] = v.replace(/\s*/g, "").split("=")[1];
        });
      }
      return attr;
    },
    checkMeiyaToken() {
      // let hash = window.location.hash
      // let urlSearch = window.location.search
      // let token = ''
      // // 1. 监测是否传递参数
      // if (hash.indexOf('?') === -1) {
      //   this.messageText = '没有检测到用户信息传递，或者参数格式错误 ….'
      //   return
      // }
      // // 2. 获取url参数值
      // let urlData = this.formatUrlData(hash.split('?')[1])
      // // 3. 检测是否传递token值
      // if (!urlData || !urlData['token']) {
      //   this.messageText = '没有检测到用户信息传递，或者参数格式错误 …'
      //   return
      // }
      // // 4. 格式化token
      // if (urlData['token']) {
      //   let encodeToken = urlData['token']
      //   // 检测 + 号
      //   let neeToken = encodeToken.replace(/%20([^0-9]{1})/ig, ($0, $1, $2, $3) => {
      //     return '%2b' + $1
      //   })
      //   token = decodeURIComponent(neeToken)
      // }

      let token = "";
      // 检测是否存在 token
      if (!this.$route.query.hasOwnProperty("token")) {
        this.messageText = "没有检测到用户信息传递，或者参数格式错误 …";
        return;
      } else {
        token = this.$route.query.token;
      }

      token = token.replace(/(\s|\n)/g, "");
      this.loginByToken(token);
    },
    // 使用 token 登陆
    loginByToken(token) {
      this.token = token;
      this.$store.commit("changeToken", this.token);
      this.$store.commit("changehasToken", this.token);
      localStorage.setItem("kd_token", JSON.stringify(this.token)); // 本地存储
      this.LoginOnePointTwo(token);
    },
    urlTo() {
      window.open("http://wpa.qq.com/msgrd?v=3&uin=2114655397");
    },
    namefous() {
      this.$refs.name.focus();
    },
    pswfous() {
      this.$refs.psw.focus();
    },
    inputClick() {
      if (navigator.userAgent.indexOf("Firefox") >= 0) {
        this.keepPsw = !this.keepPsw;
      }
    },
    setLocation() {
      let list = {};
      if (this.keepPsw == true) {
        list = {
          iskeepPsw: this.keepPsw,
          accounts: this.accounts,
          passwords: this.passwards,
        };
      } else {
        list = {
          iskeepPsw: this.keepPsw,
          accounts: "",
          passwords: "",
        };
      }
      localStorage.setItem("kd_logy", JSON.stringify(list));
    },
    getLocation() {
      let str = localStorage.getItem("kd_logy");
      if (str) {
        let strs = JSON.parse(str);
        if (strs.iskeepPsw == true) {
          this.keepPsw = true;
          this.accounts = strs.accounts;
          this.passwards = strs.passwords;
        } else {
          this.keepPsw = false;
        }
      } else {
        this.keepPsw = false;
      }
    },
    loginIn() {
      getUpgrade(this); //检测是否是系统升级时间
      if (!this.hasLogin) {
        this.$refs.login.style.background = "#2a497e";
        setTimeout(
          function () {
            this.$refs.login.style.background = "#4677c7";
          }.bind(this),
          50
        );
        setTimeout(
          function () {
            if (this.accounts == "") {
              this.alermtext = "请输入账号";
              this.alermShow = true;
            } else {
              if (this.passwards == "") {
                this.alermtext = "请输入密码";
                this.alermShow = true;
              } else {
                if (this.passwards.length < 6 || this.passwards.length > 16) {
                  this.alermtext = "密码格式有误";
                  this.alermShow = true;
                } else {
                  //发出请求
                  if (window.localStorage) {
                    this.setLocation();
                  }
                  if (this.isClickone) {
                    this.loginFirst();
                  }
                  // 缓存刷新
                  this.refreshDept();
                }
              }
            }
          }.bind(this),
          70
        );
      } else {
        // window.onkeydown= function(e){window.event? window.event.returnValue = false : e.preventDefault();}
      }
    },
    loginFirst() {
      this.isClickone = false;
      let url = `${this.loginServer}auths/user/login`;
      //   let url = `${this.domainPower}user/login`
      let postData = {
        loginName: this.accounts,
        loginPwd: this.passwards,
        system: systemCode,
        validCode: "string",
      };
      this.axios
        .post(url, postData)
        .then((response) => {
          let data = response.data;
          if (data.code && data.code[0] == "R") {
            this.token = data.data;
            this.$store.commit("changeToken", this.token);
            this.personRefresh(); // 登录成功后，刷新重点人用户
          } else {
            this.isClickone = true;
            if (data.code && data.code[0] == "E") {
              this.alermtext = "服务器异常";
            } else {
              this.alermtext = data.msg;
            }
            this.alermShow = true;
          }
        })
        .catch((error) => {
          this.isClickone = true;
          this.alermtext = "请检查网络是否正常";
          this.alermShow = true;
        });
    },
    personRefresh() {
      this.axios({
        method: "get",
        url: `${this.reptilianService}user/refresh`,
        headers: {
          token: this.token,
          system: systemCode,
        },
      }).then((res) => {
        let data = res.data;
        if (data.code && data.code[0] == "R") {
          //正常登录
          this.LoginOnePointTwo();
        } else {
          // 登录失败
          this.$store.commit("changeLogin", false);
        }
      });
    },
    //缓存刷新接口
    refreshDept() {
      this.axios.post(`${this.domainPower}refresh/dept`).then((res) => {
        if (res.data && res.data.code[0] == "R") {
          // 缓存刷新调用
        } else {
          // 缓存刷新失败
        }
      });
      // this.axios.post(`${this.domainPower}refresh/user`).then(res=>{
      //   if(res.data&&res.data.code[0] == 'R'){
      //     // 缓存刷新调用
      //   }else{
      //     // 缓存刷新失败
      //   }
      // })
    },
    LoginOnePointTwo() {
      // console.log(this.token);
      console.log("348", this.$store.state.hasToken);
      this.axios({
        method: "post",
        url: `${this.loginServer}auths/menus/${systemCode}`,
        headers: {
          token: this.token,
          system: systemCode,
        },
      }).then((res) => {
        this.authList = res.data.data || [];
        this.$store.commit("changeAuthList", this.authList);
        localStorage.setItem("kd_authList", JSON.stringify(this.authList));
        //新增重点人，系统配置的重点人分析
        // this.newLeadLibrary([{ menuLink: '/settings', children: [] }]);
        this.loginSecond();
      });
    },
    newLeadLibrary(arr) {
      // 新增 信息预警  人员分析
      let data = {
        menuId: 365,
        menuLink: "/person",
        menuName: "重点人",
        bigImage: "menuicon01",
        menuParentId: 0,
        orderNumber: 0,
        children: [
          {
            children: [
              {
                menuId: 368,
                menuLink: "/person/event-warning",
                menuName: "预警监测",
                menuParentId: 334,
                orderNumber: 10,
              },
              {
                menuId: 369,
                menuLink: "/person/man-warning",
                menuName: "人员动态",
                menuParentId: 334,
                orderNumber: 10,
              },
            ],
            menuId: 366,
            menuLink: "/person/man-warning",
            menuName: "信息预警",
            menuParentId: 334,
            orderNumber: 0,
          },
          {
            children: [
              {
                menuId: 370,
                menuLink: "/person/personnel-analysis",
                menuName: "微博",
                menuParentId: 334,
                orderNumber: 10,
              },
              {
                menuId: 369,
                menuLink: "/person/man-warning",
                menuName: "Twitter",
                menuParentId: 334,
                orderNumber: 10,
              },
              {
                menuId: 369,
                menuLink: "/person/man-warning",
                menuName: "Facebook",
                menuParentId: 334,
                orderNumber: 10,
              },
            ],
            menuId: 367,
            menuLink: "/person/personnel-analysis",
            menuName: "人员分析",
            bigImage: "menuicon02",
            menuParentId: 334,
            orderNumber: 0,
          },
        ],
      };
      arr.push(data);
      // 新增 系统配置的重点人分析
      var settings = arr.find((v) => v.menuLink == "/settings");
      var childs = settings.children;
      var personData = {
        bigImage: "logo2",
        children: [
          {
            bigImage: "logo7",
            children: [],
            menuId: 371,
            menuLink: "/settings/eventwarning",
            menuName: "事件信息预警",
            menuParentId: 370,
            orderNumber: 0,
          },
          {
            bigImage: "logo7",
            children: [],
            menuId: 372,
            menuLink: "/settings/manwarning",
            menuName: "人员信息预警",
            menuParentId: 370,
            orderNumber: 0,
          },
        ],
        menuId: 370,
        menuName: "重点人分析",
        menuParentId: 380,
        orderNumber: 2,
      };
      childs.push(personData);
      this.$store.commit("changeAuthList", this.authList);
      localStorage.setItem("kd_authList", JSON.stringify(this.authList));
    },
    loginSecond() {
      this.axios({
        method: "get",
        url: `${this.loginServer}depts/deptInfo`,
        headers: {
          token: this.token,
          system: systemCode,
        },
      })
        .then((response) => {
          let data = response.data;
          if (data.code.indexOf("R") != -1) {
            this.companyInfo = data ? data.data.deptInfos : "";
            localStorage.setItem("p_info", JSON.stringify(this.companyInfo));
            this.$store.commit("changeParentInfo", this.companyInfo);
            localStorage.setItem("kd_token", JSON.stringify(this.token));
            this.$store.commit("changehasToken", this.token);
            if (data.data.skin && data.data.skin.length > 0) {
              this.$store.commit("changeSkin", data.data.skin);
            }
            // 将地区初始化为本地
            let city = this.companyInfo.cityId;
            let province = this.companyInfo.provinceId || "";
            if (!city) {
              if (typeof city == "undefined") city = "";
              if (typeof city == "number") city = city;
            }
            this.$store.commit("changeProvince", province);
            this.$store.commit("changeCity", city);

            this.deptSystem = data.data.deptSystem;
            let periodDate = this.deptSystem.periodDate; // 过期时间
            if (Date.parse(new Date()) >= periodDate) {
              this.alermtext = "账号已经过期";
              this.alermShow = true;
              this.isClickone = true;
              return;
            } else {
              let isFormal = this.deptSystem.isFormal; //是否正式
              if (isFormal == 1 && Date.parse(new Date()) < periodDate) {
                //正式账号 当前时间小于期限时间
                let dueDate = new Date(parseInt(periodDate)); //到期日期
                let dueTime = dueDate.setDate(dueDate.getDate() - 30); //到期30天前日期时间撮
                let within = Date.parse(new Date()) - dueTime; //在30天范围内
                if (within >= 0) {
                  this.bankTime =
                    31 - Math.ceil(Math.abs(within) / 1000 / 60 / 60 / 24);
                }
              } else if (isFormal == 0 && Date.parse(new Date()) < periodDate) {
                let differTime = Date.parse(new Date()) - periodDate; //还剩多少时间撮
                this.bankTime = Math.ceil(
                  Math.abs(differTime) / 1000 / 60 / 60 / 24
                ); //剩余多少天
              }
            }
            localStorage.setItem("bank_time", this.bankTime);
            this.loginThird();
          } else {
            this.isClickone = true;
          }
        })
        .catch((error) => {
          this.isClickone = true;
        });
    },
    loginThird() {
      let url = `${this.loginServer}users/userInfo?system=${systemCode}`;
      this.axios({
        method: "get",
        url: `${url}`, //${this.loginServer}depts/deptInfo
        headers: {
          token: this.token,
          system: systemCode,
        },
      })
        .then((response) => {
          let data = response.data;
          if (data.code[0] == "R") {
            if (data.data["isProxy"]) {
              //isProxy == 1 开通vpn，有境外导航
              this.needVpn = true;
            } else {
              this.needVpn = false;
            }
            let loginTime = Date.parse(new Date()) / 1000;
            let param = data.data;
            param.mgrFlag = this.companyInfo.mgrFlag;
            param.companyId = this.companyInfo.deptId;
            param.provinceId = this.companyInfo.provinceId || "";
            param.countyId = this.companyInfo.countyId || "";
            param.cityId = this.companyInfo.cityId || "";
            param.deptName = this.companyInfo.deptName || "";
            param.mgrDeptLevel = this.companyInfo.deptLevel;
            param.loginName91 = "";
            param.isChangzhou = false;
            let isFormal = this.deptSystem.isFormal; //是否正式
            if (isFormal) {
              param.isOfficial = "1";
            } else {
              param.isOfficial = "0";
            }
            this.$store.commit("changeuserInfo", param);
            localStorage.setItem("kd_infoUser", JSON.stringify(param));
            localStorage.setItem("kd_starTime", JSON.stringify(loginTime));
            // this.sureFirst();
            this.$store.commit("changeLogin", true); // 登录成功跳转路由
            // alert(1)
            window.setTimeout(() => {
              this.$router.push("/container");
            }, 500);
          } else {
            this.isClickone = true;
          }
        })
        .catch((error) => {
          this.isClickone = true;
        });
    },
    sureFirst() {
      this.$store.commit("changeVerify", this.verifyPage);
      this.$store.commit("changePageControl", this.pageControl);
      localStorage.setItem("kd_verify", JSON.stringify(this.verifyPage));
      localStorage.setItem("kd_pageControl", JSON.stringify(this.pageControl));
      this.$store.commit("changeAuthList", this.authList);
      localStorage.setItem("kd_authList", JSON.stringify(this.authList));

      window.setTimeout(() => {
        this.$store.commit("changeLogin", true);
        this.$router.push("/container");
      }, 1000);
    },
  },
  deactivated: function () {
    this.$destroy();
  },
  computed: {
    ...mapState("common", ["loginServer", "userServer"]),
  },
  watch: {
    accounts(n, o) {
      if (this.alermtext == "请输入账号" && this.alermShow == true) {
        this.alermtext == "";
        this.alermShow = false;
      }
      if (n) {
        if (this.accounts.indexOf(" ") >= 0) {
          this.accounts = o;
        }
      }
      if (n != o) {
        this.alermtext == "";
        this.alermShow = false;
      }
    },
    passwards(n, o) {
      if (this.alermtext == "请输入密码" && this.alermShow == true) {
        this.alermtext == "";
        this.alermShow = false;
      }
      if (n) {
        if (this.passwards.indexOf(" ") >= 0) {
          this.passwards = o;
        }
      }
      if (n != o) {
        this.alermtext == "";
        this.alermShow = false;
      }
    },
  },
};
</script>

  <style lang='stylus' rel="stylesheet/stylus" scoped>
  @import '~@/common/stylus/variable.styl';
  @import '~@/common/stylus/mixin.styl';

  .check-token {
    wh(100%, 100%);
    position: absolute;
    left: 0;
    top: 0;
    background-color: #f2f5f8;

    &__box {
      wh(372px, 237px);
      margin: 0 auto;
      margin-top: 350px;
    }

    &__box--pc {
      display: block;
      margin: 0 auto;
    }

    &__box--loading {
      display: block;
      margin: 0 auto;
      margin-top: 60px;
      color: #7d7f82;
    }

    &__box--txt {
      display: block;
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin-top: 25px;
    }
  }
</style>
